<template>

  <section id="muro-form">

    <div class="row mt-3">
      <div class="col-12">
        <h3 class="section-head-title ps-0 mb-3">
          {{ subtitle }}
        </h3>
      </div>
    </div>

    <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
      <div class="card-body d-flex flex-column mh-700">
        <div class="row flex-grow-1 mt-3">
          <div class="col-12 col-lg-4 d-flex flex-column mt-3 mt-lg-0">
            <h5 class="font-main text-secondary">
              Autor de la publicación
            </h5>
            <div class="card flex-grow-1">
              <div class="card-body">
                
                <div class="row" v-if="!switch_cambiarAutor">
                  <div class="col-12">
                    <div 
                      class="card"
                      :class="selected_profile.id_perfil !== default_profile.id_perfil ? 'opacity-50' : '' "
                      :style="selected_profile.id_perfil == default_profile.id_perfil ? `border-color: ${selected_profile.color};` : '' "
                      >
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="flex-shrink-0">
                            <img 
                              :src="selected_profile.imagen_perfil"
                              class="img-obj img-obj-30 img-obj-circle"
                            >
                          </div>
                          <div class="flex-grow-1 ms-2">
                            {{/*this.usuario.primer_nombre +' '+ this.usuario.apellido_paterno*/ this.selected_profile.nombre_perfil}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-3" v-if="autor_post">
                  <div class="col-12">
                    <div class="alert alert-light border d-flex align-items-center justify-content-between">
                      <label 
                        for="cambiarAutor"
                        class="form-check-label link-cursor text-secondary"
                      >
                      ¿Cambiar perfil del autor?
                      </label>
                      <div class="form-check form-switch">
                        <input 
                          id="cambiarAutor"
                          class="form-check-input link-cursor" 
                          type="checkbox" 
                          role="switch"
                          v-model="switch_cambiarAutor"
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div 
                  v-if="switch_cambiarAutor"
                  class="row">
                  <div class="col-12">
                    <v-select
                      class="selvue-custom mb-3"
                      :options="profile_list"
                      v-model="selected_profile"
                      label="nombre_perfil"
                      placeholder="Seleccionar"
                      :clearable="false"
                      >
                    </v-select>
                  </div>
                </div>

                <div v-if="switch_cambiarAutor && selected_profile !== '' "
                  class="row">
                  <div class="col-12">
                    <label class="text-secondary mb-1">
                      <font-awesome-icon
                        icon="circle-user" 
                        class="color-secondary"
                      />
                      Perfil de publicación
                    </label>
                    <div 
                      class="card border-width-2"
                      :style="`border-color: ${selected_profile.color};`"
                      >
                      <div class="card-body p-2">
                        <div class="d-flex align-items-center">
                          <div class="flex-shrink-0">
                            <img 
                              :src="selected_profile.imagen_perfil"
                              class="img-obj img-obj-30 img-obj-circle"
                            >
                          </div>
                          <div class="flex-grow-1 ms-2 text-secondary">
                            {{ selected_profile.nombre_perfil  }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 d-flex flex-column mt-3 mt-lg-0">
            <h5 class="font-main text-secondary">
              Mensaje
            </h5>
            <div class="card flex-grow-1">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">Añade el mensaje</label>
                    <textarea 
                      class="form-control textarea-custom" 
                      maxlength="500"
                      v-model="mensaje"
                      rows="3"
                    >
                    </textarea>
                    <div class="form-text text-end">
                      {{ mensaje.length }}/150
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 d-flex flex-column mt-3 mt-lg-0">
            <h5 class="font-main text-secondary">
              Imagen
            </h5>
            <div class="card flex-grow-1">
              <div class="card-body">
                <UploadImg 
                  @sendImg="handleMainImg" 
                  v-if="news_main_img.base64 == ''"
                />
                <div 
                  v-if="news_main_img.base64 !== ''"
                  class="upload-image"
                >
                  <div class="upload-image-preview">
                    <button 
                      @click="deletePreview()"
                      class="upload-image-preview-remove"   
                    >
                        <font-awesome-icon icon="times"/>
                    </button>
                    <img 
                      class="upload-image-preview-img"
                      :src="news_main_img.base64"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2">
              <button
                class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                @click="goToList()" 
                type="button"
                v-if="editing">
                Cancelar
              </button>
              <button 
                class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                @click="goToList()" 
                type="button"
                v-if="!editing">
                Volver
              </button>

              <button
                class="btn btn-custom-color-blue border-round-50 mw-100"
                :class="mensaje.length > 0 ? 'add__submit-button--active':''"
                @click="comprobarAnd()? addNewPost():''"
                type="button"
                :disabled="mensaje.length === 0"
                v-if="!editing"
              >
                Enviar
              </button>
              <button
                class="btn btn-custom-color-blue border-round-50 mw-100"
                :class="mensaje.length > 0 ? 'add__submit-button--active':''"
                @click="comprobarAnd() ? editingPost():''"
                type="button"
                :disabled="mensaje.length === 0"
                v-if="editing"
              >
                Guardar
              </button>
            </div>
          </div>
        </div> 

      </div>
    </div>
    <Spinner v-if="show_spinner" />
  </section>

</template>

<script>
import Spinner from "../Spinner.vue";
import { mapActions,mapState } from "vuex";

import UploadImg from "../UploadImg.vue";
export default {
  props: {
    obj: Object,
  },
  components: { Spinner, UploadImg },
  data() {
    return {
      usuario: this.$ls.get("user"),
      id_usuario: this.$ls.get("user").id_usuario,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      segment: "perfil",
      mensaje: "",
      open_select_list: "",
      default_profile: {
          id_perfil: this.$ls.get("user").id_usuario,
          nombre_perfil: this.$ls.get("user").primer_nombre +" "+this.$ls.get("user").apellido_paterno,
          color: '#0072f7',
          imagen_perfil: this.$ls.get("user").imagen_perfil ? this.$ls.get("user").imagen_perfil : require("@/assets/img/user_default_img.svg"),
          id_estado_fk: "1",
      },
      selected_profile: {},
      subtitle: "",
      news_main_img: {
        width: "",
        height: "",
        base64: "",
        src: "",
        format: "",
      },
      post_to_edit: null,
      file_img: null,
      webpImg: null,
      editing: false,
      profiles_limit: "200",
      show_spinner: false,
      platform:'2',

      //FF
      switch_cambiarAutor: true,
      autor_post: false
    };
  },
  watch: {
    segment() {
      this.open_select_list = false;
      this.select.perfil = {
        id: "",
        name: "Perfil",
      };
    },
    file_img(fil) {
      if (fil !== null) {
        if (fil == undefined) {
          setTimeout(() => {
            this.file(fil);
          }, 200);
        }
        if (fil !== undefined) {
          this.file(fil);
        }
      }
    },

    //FF
    switch_cambiarAutor(value) {
      if(value) {
        this.selected_profile = "";
      }
      else {
        this.selected_profile = this.default_profile;
      }
    }
  },
  async mounted() {
    this.subtitle = "Crea una publicación";
    this.getProfileList();
    if (this.$route.params.id) {
      this.subtitle = "Edita una publicación";
      this.editing = true;
      await this.getPostToEdit(this.$route.params.id);
    }
    else{
      this.setDefault();
    }
    //FF
    if(this.$route.name === "post-add") {
      this.switch_cambiarAutor = false;
      this.autor_post = true;
    }
    if(this.$route.name === "post-edit") {
      this.editComprobarPerfil();
    }
  },
  async created(){
    
  }, 
  computed: {
    ...mapState("wallModule", ["profile_list", "post_edit"]),
  },
  methods: {
    ...mapActions("wallModule", ["addPost", "getProfiles", "editPost", "getOnePostById"]),
    
    setDefault(){
      this.selected_profile = this.default_profile;
    },

    async getPostToEdit(id_){
      let data_page = {
          id_empresa: this.id_empresa,
          id_muro: [id_],
          plataforma: this.platform
        }
      await  this.getOnePostById(data_page);
      this.post_to_edit = this.post_edit;
      this.prepareEdition();
    },

    async getProfileList(){
      let data_ = {
        id_empresa: this.id_empresa,
        limit: this.profiles_limit,
        last_id: "",
      }
      await this.getProfiles(data_);
    },

    async addNewPost(){
      let data_ = this.getPostData();
      await this.addPost(data_).then(res =>{
        if(res != null){
          this.$toast.open({
            message:`La publicación ha sido creada correctamente.`,
            type:'success',
            duration:6000,
            position:'top-right'
          })
          this.goToList();
        }else{
          this.$toast.open({
            message:`Ocurrió un error al crear la publicación, intentalo nuevamente.`,
            type:'error',
            duration:0,
            position:'top-right'
          })
        }
      });
    },
    async editingPost(){
      await this.editPost(this.getPostData('editar')).then(res =>{
        if(res != null){
          this.$toast.open({
            message:`La publicación ha sido editada correctamente.`,
            type:'success',
            duration:6000,
            position:'top-right'
          })
          this.goToList();
        }else{
          this.$toast.open({
            message:`Ocurrió un error al editar la publicación, intentalo nuevamente.`,
            type:'error',
            duration:0,
            position:'top-right'
          })
        }
      });
    },

    getPostData(type){
      let imagen_ = '';
      let formato_ = '';
      let id_muro_ = '';
      if(type == 'editar')
      {
        id_muro_ = this.post_to_edit.id_muro;
        if (this.news_main_img.base64 !== this.post_to_edit.imagen_muro && this.news_main_img.base64!="") {
          imagen_ = this.webpImg.split(",")[1]
          formato_ = this.webpImg.split(":")[1].split(";")[0]
        }else if(this.post_to_edit.imagen_muro==null && (this.news_main_img == "" || this.news_main_img.base64=="")){
          imagen_ = '';
          formato_ = "";
        }
        else if(this.post_to_edit.imagen_muro!=null && (this.news_main_img.format!="")){
          imagen_ = this.webpImg.split(",")[1]
          formato_ = this.webpImg.split(":")[1].split(";")[0]
        }
        else{
          imagen_ = "delete";
          formato_ = "";
        }
      }else if(this.news_main_img != "" && this.news_main_img.format!=""){
        imagen_ = this.webpImg.split(",")[1];
        formato_ =  this.webpImg.split(":")[1].split(";")[0]
      }
      let type_profile_ = this.selected_profile.id_perfil == this.default_profile.id_perfil ? "1" : "2";
      let data_ = {
        id_usuario: this.id_usuario,
        id_empresa: this.id_empresa,
        imagen_muro: imagen_,
        imagen_formato:formato_,
        video_muro: "",
        video_formato: "",
        texto: this.mensaje,
        usuario_perfil: type_profile_,
        id_objeto:this.selected_profile.id_perfil
      };
      if(type == 'editar') data_.id_muro = id_muro_;
      
      return data_;
    },
    
    goToList() {
      this.$router.push({name:"wall-list"});
    },
    prepareEdition() {
      this.mensaje = this.post_to_edit.texto;
      this.news_main_img.base64 = this.post_to_edit.imagen_muro == null?'':this.post_to_edit.imagen_muro;
      if(this.post_to_edit.usuario!=null) {
        this.default_profile.nombre_perfil = this.post_to_edit.usuario[0].primer_nombre+" "+this.post_to_edit.usuario[0].apellido_paterno;
        this.default_profile.id_perfil = this.post_to_edit.usuario[0].id_usuario;
        this.default_profile.imagen_perfil = this.post_to_edit.usuario[0].imagen_perfil;
      }
      else if(this.post_to_edit.usuario==null && this.post_to_edit.perfil!=null){
        this.selected_profile = this.post_to_edit.perfil;
      }
    },

    //select imagen
    handleMainImg(img) {
      this.news_main_img = {
        width: img.width,
        height: img.height,
        base64: img.base64,
        src: img.src,
        format: img.format,
        all: img.all,
      };
      this.file_img = img.all;
    },
    // Llama a agregar o editar segun $route.name
    file(file) {
      fetch(file).then(res => res.blob()).then(blob_file => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        //declarar el cambas para la transformacion
        let currentImg = "";
        let webpImg = "";
        let convertedImg = "";
        //Deja en 0 las variables si están siendo utilizadas
        if (currentImg != "" || webpImg != "" || convertedImg != "") {
          URL.revokeObjectURL(currentImg);
          convertedImg = "";
          currentImg = "";
          webpImg = "";
        }
        //lee el archivo
        currentImg = URL.createObjectURL(blob_file);
        webpImg = new Image();
        //utiliza onload  para extraer y hacer el cambio de tipo de archivo
        webpImg.onload = () => {
          canvas.width = webpImg.naturalWidth;
          canvas.height = webpImg.naturalHeight;
          //pinta la imagen en el canvas
          ctx.drawImage(webpImg, 0, 0, canvas.width, canvas.height);
          //combierte la imagen del canvas a webp
          convertedImg = canvas.toDataURL("image/webp", 1.0);
          //insert
          this.webpImg = convertedImg
          //console.log("webp", convertedImg);
        };
        webpImg.src = currentImg;
      })
    },
    // Editar notificación
    comprobarAnd() {
      let validate = false;
      if(this.segment == "perfil" &&
          this.mensaje != "") validate = true;
      else validate = false;
      return validate;
    },
    // Obtener perfiles
    deletePreview(){
      this.news_main_img = {
        width: "",
        height: "",
        base64: "",
        src: "",
        format: "",
      };
      this.file_img = null;
      this.webpImg = null;
    },
    
    //FF
    editComprobarPerfil() {
      
      let user_actual_id = this.$ls.get("user").id_usuario;
      if(this.post_to_edit.perfil !== null) {
        this.switch_cambiarAutor = true;
        this.selected_profile = this.post_to_edit.perfil;
      }
      else if(this.post_to_edit.usuario!=null && this.post_to_edit.usuario.length>0 && 
              this.post_to_edit.usuario[0]!=null && user_actual_id == this.post_to_edit.usuario[0].id_usuario)
      {
        this.switch_cambiarAutor = false;
        this.autor_post = true;
      }
      else  {
        this.switch_cambiarAutor = false;
        this.autor_post = false;
      }
    }
  },
};
</script>
